.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --shadow-card: 0px 0px 0px 1px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  --transition-speed: 200ms;
  --gap-xs: 0.25rem;
  --gap-s: 0.5rem;
  --gap-m: 1rem;
  --gap-l: 2rem;
  --gap-xl: 4rem;
  --gap-2xl: 6rem;
  --gap-3xl: 8rem;
  --header-height: 70px;
  --container-width-xs: 20rem;
  --container-width-s: 30rem;
  --container-width-m: 50rem;
  --container-width-l: 80rem;
  --icon-size-xxs: 0.5rem;
  --icon-size-xs: 0.8rem;
  --icon-size-s: 1.25rem;
  --icon-size-m: 1.75rem;
  --icon-size-l: 2.5rem;
  --icon-size-xl: 3.25rem;
  --text-xs: 450 12px/1.4 "Mona Sans", sans-serif;
  --text-s: 450 14px/1.5 "Mona Sans", sans-serif;
  --text-base: 450 16px/1.5 "Mona Sans", sans-serif;
  --text-l: 700 20px/1.3 "FK Grotesk", "Mona Sans", sans-serif;
  --text-xl: 700 24px/1.3 "FK Grotesk", "Mona Sans", sans-serif;
  --text-2xl: 700 30px/1.2 "FK Grotesk", "Mona Sans", sans-serif;
  --text-3xl: 700 42px/1.15 "FK Grotesk", "Mona Sans", sans-serif;
  --text-hero-m: 500 56px/1.1 "FK Grotesk", "Mona Sans", sans-serif;
  --text-hero-l: 500 72px/1 "FK Grotesk", "Mona Sans Bold", sans-serif;
  --text-hero-xl: 500 100px/1 "FK Grotesk", "Mona Sans", sans-serif;
  --black: #000;
  --blackA0: transparent;
  --blackA1: rgba(0, 0, 0, 0.012);
  --blackA2: rgba(0, 0, 0, 0.027);
  --blackA3: rgba(0, 0, 0, 0.047);
  --blackA4: rgba(0, 0, 0, 0.071);
  --blackA5: rgba(0, 0, 0, 0.09);
  --blackA6: rgba(0, 0, 0, 0.114);
  --blackA7: rgba(0, 0, 0, 0.141);
  --blackA8: rgba(0, 0, 0, 0.22);
  --blackA9: rgba(0, 0, 0, 0.439);
  --blackA10: rgba(0, 0, 0, 0.478);
  --blackA11: rgba(0, 0, 0, 0.565);
  --blackA12: rgba(0, 0, 0, 0.91);
  --white: #fff;
  --whiteA0: hsla(0, 0%, 100%, 0);
  --whiteA1: hsla(0, 0%, 100%, 0);
  --whiteA2: hsla(0, 0%, 100%, 0.013);
  --whiteA3: hsla(0, 0%, 100%, 0.034);
  --whiteA4: hsla(0, 0%, 100%, 0.056);
  --whiteA5: hsla(0, 0%, 100%, 0.086);
  --whiteA6: hsla(0, 0%, 100%, 0.124);
  --whiteA7: hsla(0, 0%, 100%, 0.176);
  --whiteA8: hsla(0, 0%, 100%, 0.249);
  --whiteA9: hsla(0, 0%, 100%, 0.386);
  --whiteA10: hsla(0, 0%, 100%, 0.446);
  --whiteA11: hsla(0, 0%, 100%, 0.592);
  --whiteA12: hsla(0, 0%, 100%, 0.923);
  --amber1: #fefdfb;
  --amber2: #fff9ed;
  --amber3: #fff4d5;
  --amber4: #ffecbc;
  --amber5: #ffe3a2;
  --amber6: #ffd386;
  --amber7: #f3ba63;
  --amber8: #ee9d2b;
  --amber9: #ffb224;
  --amber10: #ffa01c;
  --amber11: #ad5700;
  --amber12: #4e2009;
  --cyan1: #f6fefe;
  --cyan2: #f2fdfd;
  --cyan3: #dffbfa;
  --cyan4: #c9f8f7;
  --cyan5: #adf5f3;
  --cyan6: #9aefec;
  --cyan7: #89e6e4;
  --cyan8: #3bcec9;
  --cyan9: #61e5e3;
  --cyan10: #48dbd6;
  --cyan11: #138996;
  --cyan12: #083a40;
  --green1: #f5fffa;
  --green2: #f0fff8;
  --green3: #dcfeed;
  --green4: #c4fde1;
  --green5: #a6fcd3;
  --green6: #8efbc6;
  --green7: #7af5b8;
  --green8: #37cd82;
  --green9: #5aeda3;
  --green10: #45e394;
  --green11: #1c875b;
  --green12: #052e19;
  --green-brand: #00ec97;
  --red1: #fff6f5;
  --red2: #fff2f0;
  --red3: #ffe0db;
  --red4: #ffc9c2;
  --red5: #ffafa3;
  --red6: #ff978a;
  --red7: #fe8471;
  --red8: #d95d4a;
  --red9: #f77969;
  --red10: #ee6e5d;
  --red11: #a81600;
  --red12: #4b0a02;
  --sand1: #fdfdfc;
  --sand2: #f9f9f8;
  --sand3: #f3f3f2;
  --sand4: #eeeeec;
  --sand5: #e9e9e6;
  --sand6: #e3e3e0;
  --sand7: #dbdbd7;
  --sand8: #c8c7c1;
  --sand9: #90908c;
  --sand10: #868682;
  --sand11: #706f6c;
  --sand12: #1b1b18;
  --violet1: #f7f6fe;
  --violet2: #f3f2fd;
  --violet3: #e3e1f9;
  --violet4: #cfccf5;
  --violet5: #b7b2f0;
  --violet6: #a39cec;
  --violet7: #938be4;
  --violet8: #604cc8;
  --violet9: #8279e2;
  --violet10: #6e63d4;
  --violet11: #2a297a;
  --violet12: #191641;
  --violet-brand: #9797ff;
  @media (max-width: 1000px) {
    --gap-l: 1.5rem;
    --gap-xl: 2rem;
    --gap-2xl: 4rem;
    --gap-3xl: 6rem;
    --text-l: 700 16px/1.3 "FK Grotesk", "Mona Sans", sans-serif;
    --text-xl: 700 20px/1.3 "FK Grotesk", "Mona Sans", sans-serif;
    --text-2xl: 700 24px/1.3 "FK Grotesk", "Mona Sans", sans-serif;
    --text-3xl: 700 28px/1.2 "FK Grotesk", "Mona Sans", sans-serif;
    --text-hero-m: 500 36px/1.2 "FK Grotesk", "Mona Sans", sans-serif;
    --text-hero-l: 500 48px/1.2 "FK Grotesk", "Mona Sans", sans-serif;
    --text-hero-xl: 500 72px/1.2 "FK Grotesk", "Mona Sans", sans-serif;
  }

  @media (max-width: 600px) {
    --gap-2xl: 3rem;
    --gap-3xl: 4rem;
    --header-height: 54px;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap; /* Ensure flex items wrap on smaller screens */
}

.Pattern-module_pattern__Ngp7v {
  min-height: 540px;
  display: flex;
  align-items: center;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGeSURBVHgB7doxTisxEAbgeY/mvQro6NiSDo6QkpJbcA2OwjWooKQMJ2DpKENJBV7FEYoBeQSIZr9PGk2cItWvsdfZnSBjKHVf6rnUbdD1N8g4K7VX6jhIEaycofaTIEWwcoam0yFYOYe179WiQ7Byhk8+8wnB6munlHNWgmD1tUGyFSYIVl8bJFcOCYLV106s/aBrJ2hNE+qo1GmpRanz2J5aB6X+x/oQv/l+FWz5E/O1iHU4pom0W/u0/uoZahnrgN2VGuv6Jpidl1+o2T5BznkrfKj9MdZT6l9836r+3k2pq1KXMVNz3gpbU7hOmj49AQ7x/lJ0WWsK5xhv2+AYkHQR29vbddDluqFvbNZPQZdg9S07az4gWH3tHZVgJQhW3xjb4XIZyo+Z3nffHN79CZ1gYuXc1b4KEytFsHLGptMhWDlj7Q9BimDlbJ4Ex4AftggHdwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIpXoUVLSWulnzoAAAAASUVORK5CYII=);
  background-size: 75px 75px;
  background-repeat: repeat;
  background-position: top;
  max-width: 68%;
  margin: 0 auto;
  margin-top: 32px;
  justify-content: center;
  text-align: center;
}
.motto {
  max-width: 500px;
  background: #fff;
  padding: 40px;
}
.motto h1 {
  font: var(--text-hero-l);
  letter-spacing: -3px;
  color: var(--sand12);
  font-weight: 800;
  margin-bottom: 10px;
  margin-top: 10px;
}
.motto p {
  font-family: "Mona Sans";

  font: var(--text-l);
  color: var(--sand12);
}
header {
  --nav-height: 75px;
  z-index: 1000;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: var(--nav-height);
  box-shadow: 0 1px 0 var(--sand6);
  padding: 0 1.75rem;
  display: flex;
  align-items: center;
}

.header-wrapper {
  width: 1000px;
  margin: 0 auto;
  height: 30px;
  display: flex;
  justify-content: space-between;
}

.header-wrapper nav {
  width: 700px;
}

.header-wrapper nav ul {
  display: flex;
  margin-top: 0;
}

header a {
  color: #000;
  font-family: "Mona Sans";
  font-weight: 600;
  text-decoration: none;
  padding-top: 5px;
  display: block;
  transition: transform 0.3s ease, font-weight 0.3s ease; /* Add transition for smooth animation */
}

header a:hover {
  transform: scale(1.1);
  font-weight: 700; /* Make font bold on hover */
}

.header-wrapper nav ul li {
  width: auto;
  margin-right: 30px;
  list-style-type: none;
}

.grey {
  --section-background-color: var(--grey-brand);
  width: 100%;
  height: auto;
  background: var(--section-background-color);
  margin-top: 40px;
  padding-bottom: 140px;
}

.green {
  --section-background-color: var(--green-brand);
  width: 100%;
  height: auto;
  background: var(--section-background-color);
  margin-top: 10px;
  padding-bottom: 140px;
}

.green h1 {
  font: var(--text-hero-l);
  letter-spacing: -3px;
  color: var(--sand12);
  font-weight: 400;
  margin-top: 10px;
  padding-top: 80px;
}

.green h2 {
  font: var(--text-hero-l);
  letter-spacing: -3px;
  font-size:40px !important;
  color: var(--sand12);
  font-weight: 400;
  margin-top: 10px;
  padding-left:20px;
}




.container {
  width: 1000px !important;
  margin: 0 auto;
}

body {
  margin: 0;
  font-family: "Mona Sans";
}

.content {
  display: flex;
}

.content p {
  font: var(--text-l);
  color: var(--sand12);
  display: block;
  width: 100%;
}

.content span {
  font: 500 22px/1 "FK Grotesk", "Mona Sans Bold", sans-serif;
  color: var(--sand12);
  font-weight: 800;
}

.black {
  background: #000;
  min-height: 400px;
  color: #fff;
  padding-bottom: 140px;
}

.black p {
  color: #fff;
}

.black h2{
  font-size:30px;
  padding-top:10px;
}

.black h1,
.end h1 {
  font: var(--text-hero-l);
  letter-spacing: -3px;
  margin-top: 0px;
  padding-top: 140px;
}

.end {
  min-height: 400px;
  padding-bottom: 140px;
}

.end h1 {
  margin-bottom: 30px;
  font-size: 40px;
}

.end p {
  font-size: 30px;
}
.flex {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

.links p {
  margin-right: 20px;
}

.links img,
.buy img {
  transition: transform 0.3s ease;
}
.links img:hover,
.buy img:hover {
  transform: scale(1.1);
}

/* Adjustments for mobile responsiveness */
@media (max-width: 768px) {
  /* Header adjustments */
  .header-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .header-wrapper img {
    max-width: 100px; /* Adjust logo size */
    margin-bottom: 10px;
  }

  .header-wrapper nav ul {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .header-wrapper nav ul li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  /* Main content adjustments */
  .Pattern-module_pattern__Ngp7v,
  .container,
  .green .container,
  .black .container,
  .end .container {
    max-width: 100%; /* Use full width */
    padding: 0 20px; /* Add padding */
  }

  .motto,
  .content,
  .buy,
  .links {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .content div,
  .buy p,
  .links p {
    padding: 10px 0; /* Add padding between items */
  }

  .content img,
  .buy img,
  .links img {
    width: 100%; /* Full width images */
    max-width: 200px; /* Limit maximum size */
    height: auto; /* Maintain aspect ratio */
  }

  /* Adjust font sizes for text */
  .motto p,
  .content p,
  .end p {
    font-size: 16px;
  }

  /* Adjustments for specific sections */
  .green,
  .black,
  .end {
    padding-bottom: 60px;
  }

  .green h1,
  .black h1 {
    padding-top: 60px;
  }

  /* Adjust the size of the navigation links */
  header a {
    font-size: 14px; /* Smaller font size for links */
  }
}

/* HEADER STYLES  */

/* Hamburger Icon Styling */
.hamburger-icon {
  display: none; /* Hide by default */
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1100; /* Above the menu */
}

/* Full-page Menu Styling */
.menu {
  display: none; /* Hide the menu by default */
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000; /* Ensure it's above other content */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 0;
}

.menu.open {
  display: flex; /* Show the menu when 'open' class is added */
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0; /* Reset default margin */
  width: 100%; /* Full width to center content */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
}

.menu ul li {
  /* Add some vertical padding for each list item */
  width: 100%;
}

.menu ul li a {
  color: white;
  font-size: 24px;
  text-decoration: none;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hamburger-icon {
    display: block; /* Show the hamburger icon on small screens */
  }

  .header-wrapper > nav {
    display: none; /* Hide the default nav menu on small screens */
  }
}
